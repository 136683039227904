<template>
  <tab-dialog
    :title="formTitle"
    :dialog="showTabDialog"
    :tabs="tabsConfig"
    :valid="valid"
    :is-editable="isEditable"
    :loading="loading"
    :dialog-props="{
      height: 520
    }"
    @on:save="handleSave"
    @on:close="close()"
    @mode:editable="$emit('mode:editable', $event)"
  >
    <template v-slot:actions>
      <v-switch :disabled="!isEditable" class="px-2 py-2" @change="" v-model="editedItem.isActive" color="primary" inset hide-details>
        <template v-slot:label>
          <p class="font-weight-bold ma-0 pa-0 mt-1 mx-1" ref="label"
          >{{ !editedItem.isActive ? $t("producer.fields.inactive") : $t("producer.fields.isActive") }}
          </p>
        </template>
      </v-switch>
    </template>
    <template v-slot:mainInformation>
      <main-section
        :item="editedItem"
        @update:valid="valid=$event"
        :is-editable="isEditable"
      />
    </template>

    <template v-slot:addressInformation>
      <a-address-form
        @update:valid="valid=$event"
        :fieldProps="fieldPropsAddress"
        :boxed="true"
        :item="editedItem"
        :is-editable="isEditable"
      />
    </template>

    <template v-slot:contactInformation>
      <contact-section
        @update:valid="valid=$event"
        :item="editedItem"
        :is-editable="isEditable"
      />
    </template>

    <template v-slot:generalInformation>
      <general-section
        @update:valid="valid=$event"
        :item="editedItem"
        :is-editable="isEditable"
      />
    </template>
  </tab-dialog>
</template>

<script>
  import TabDialog from "@/components/common/TabDialog.vue"
  import AAddressForm from "@/components/common/AAddressForm.vue"
  import MainSection from './MainSection.vue';
  import ContactSection from './ContactSection.vue';
  import GeneralSection from './GeneralSection.vue';

  export default {
    components: {
      TabDialog,
      AAddressForm,
      MainSection,
      ContactSection,
      GeneralSection,
    },
    props: {
      editedItem: {
        type: Object,
        required: true
      },
      showTabDialog: {
        type: Boolean,
        required: true
      },
      isEditable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        valid: false,
        tabsConfig: [
          { name: this.$t("producer.titles.mainInformation"), icon: 'mdi-information-slab-box', slotName: 'mainInformation', visible: true },
          { name: this.$t("producer.titles.addressInformation"), icon: 'mdi-map-marker', slotName: 'addressInformation', visible: true },
          { name: this.$t("producer.titles.contactInformation"), icon: 'mdi-account-box', slotName: 'contactInformation', visible: true },
          { name: this.$t("producer.titles.generalInformation"), icon: 'mdi-dots-horizontal', slotName: 'generalInformation', visible: true }
        ],
        fieldPropsAddress: {
          address1: { props: { color: 'secondary', outlined: true, dense: true }},
          address2: { props: { color: 'secondary', outlined: true, dense: true }},
          country: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary' }},
          state: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary' }},
          city: { props: { color: 'secondary', outlined: true, dense: true, itemColor: 'secondary' }},
          zipCode: { props: { color: 'secondary', outlined: true, dense: true }}
        }
      };
    },
    computed: {
      loading: {
        get() {
          return this.$store.getters['producer/loading']
        },
        set(val) {
          this.$store.commit('producer/SET_LOADING', val)
        },
      },
      formTitle() {
        return this.editedItem.id ? this.$t("general.titles.edit") + " " + this.$t("producer.titles.new_producer") : this.$t("general.titles.new") + " " + this.$t("producer.titles.new_producer");
      }
    },
    methods: {
      handleSave() {
        this.$emit('on:save', this.editedItem);
      },
      close() {
        this.$emit('on:close');
      }
    }
  };
</script>

<style scoped>
  /* Your styles here */
</style>
